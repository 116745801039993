.whatsapp-menu-rodape{
	color:#FFF;
	margin-bottom:0;
	position:fixed;
	bottom:0;
	right:0;
	z-index:800;

	.texto{

		@include media-breakpoint-down(md){
			display:none !important;
		}

	}

	.controls-head{
		width:100%;
		max-width:350px;
		margin-left:auto;

		@include media-breakpoint-down(md){
			max-width:100px;
		}

		& > .btn{
			width:100%;
			min-width:auto;
			border-radius:30px;
			background-color:$theme;
			color:#FFF;
			padding:20px 20px;
			position:relative;
			border:none;

			@include media-breakpoint-down(md){
				padding:0;
				width:60px;
				height:60px;
				border-radius:100%;
			}

			@include media-breakpoint-up(lg){
				border-radius:30px 30px 0 0;
			}

			&:before{
				content:'x';
				display:block;
				position:absolute;
				top:0;
				right:0;
				border-radius:0 30px 0 30px;
				background-color:$theme2;
				width:36px;
				font-size:16px;
				height:30px;
				opacity:0;
				transition:opacity 0.6s linear;
			}

			.pontos{
				display:inline-block;
				position:relative;
				width:5px;
				height:5px;
				line-height:1;
				vertical-align:middle;
				background-color:#EEEEF0;
				border-radius:100%;
				margin-right:5px;

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					width:100%;
					left:0;
					background-color:inherit;
					border-radius:100%;
					height:100%;
				}

				&:before{
					bottom:calc(100% + 3px);
				}

				&:after{
					top:calc(100% + 3px);
				}

			}

			&:focus{
				box-shadow:none;
			}

			.texto-sumido{
				display:none;
			}

			&[aria-expanded="true"]{

				&:before{
					opacity:1;
				}

				@include media-breakpoint-up(lg){
					.texto-sumido{
						display:block;
					}

					.texto-aparecendo{
						display:none;
					}
				}

			}

		}

	}

	.menu-whatsapp-rodape{
		margin-bottom:0;
		padding-bottom:6px;
		list-style-type:none;
		padding-left:0;
		background-color:$theme2;
		font-family: 'latoregular';
		max-height:200px;

		&.show{
			overflow:auto;
		}

		@include media-breakpoint-down(md){
			padding:5px;
			max-height:1770px;
			overflow:auto;

			.icon{
				margin-bottom:5px;
			}

		}

		& > li{

			@include media-breakpoint-up(lg){
				padding-left:25px;
				padding-right:25px;
				text-align:left;

				@include hover-focus{
					color:$theme;
				}

			}

			& > a{
				display:block;
				padding-top:5px;
				padding-bottom:5px;

				.icon{
					margin-right:8px;
				}

			}

			& + li > a{
				border-top:1px solid #FFF;
			}

		}

	}

	@include media-breakpoint-up(lg){
		bottom:0;
		right:0;
		padding:0;
	}

}