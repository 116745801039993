.video-incrivel{
	cursor:pointer;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	position:relative;
	margin-bottom:20px;

	&.video-peq{

		.video-controls{
			max-width:359px;
		}

	}

	&.video-small{
		
		.video-controls{
			max-width:457px;
		}

	}

	&.video-big{
		
		.video-controls{
			max-width:555px;
		}

	}

	.video-controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		justify-content:center;
		align-items:center;
	}

	@include media-breakpoint-up(lg){

		.video-controls{
			opacity:0;
		}

		&:hover{

			.video-controls{
				opacity:1;
				animation:fadeInDown 0.6s linear;
			}

		}

	}

}