.media-incrivel{

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		margin-bottom:18px;
	}

	.media-icon{

		@include media-breakpoint-up(lg){
			margin-right:15px;
		}

		@include media-breakpoint-down(lg){
			margin-bottom:15px;
		}

	}

	.media-text{

	}

	&.media-incrivel-diferente{

		@include media-breakpoint-up(lg){
			text-align:center;
		}

		.media-icon{

			@include media-breakpoint-up(lg){
				margin-right:0;
				margin-bottom:0;
				align-self:flex-end;
			}

		}

		& > *{

			@include media-breakpoint-up(lg){
				width:100%;
			}

		}

	}

}