.btn{
	font-size:14px;
	font-family: 'lato-bold';
	border-radius:30px;
	min-width:233px;
	padding:9px 12px;

	&.min-width-144{
		min-width:144px;
	}

	&.lg-min-width-inherit{
		
		@include media-breakpoint-up(lg){
			min-width:inherit;
		}

	}

	&.btn-pill{
		border-radius: 2rem;
	}

	&.btn-theme-to-theme-2{
		@include button-variant($theme,$theme);
		color:#FFF;
		border:2px outset $theme2;

		@include hover-focus{
			@include button-variant(lighten($theme2,5%),lighten($theme2,5%));
			color:#FFF !important;	
		}

	}

	&.btn-theme-2-to-theme{
		@include button-variant($theme2,$theme2);
		color:#FFF;
		border:2px outset $theme2;

		@include hover-focus{
			@include button-variant(lighten($theme,5%),lighten($theme,5%));
			color:#FFF;			
		}

	}

}