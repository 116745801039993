#loading-page{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	background-color:#FFF;
	z-index:1000;

	&.ativado{
		display:flex !important;
	}

	&.never-again{
		display:none !important;
	}

	.loader{

		// hay frand, play with these vars.
		$loader-style: double; 
		$loader-width: 0.5em;
		$loader-color: $theme;
		$loader-size: 150px;
		$loader-speed: 1s;

		$body-bg: #FFF;

		// colors by http://flatuicolors.com/
		& {
		  width: 1em;
		  height: 1em;
		  font-size: $loader-size;
		  position: relative;

		  &:before, &:after {
		    content: "";
		    top: 0;
		    display: block;
		    width: 1em;
		    height: 1em;
		    position: absolute;
		    border-width: $loader-width;
		    border-style: $loader-style;
		    border-color: transparent;
		    box-sizing: border-box;
		    border-radius: 1em;
		    animation: spin $loader-speed infinite;
		  }
		  &:after {
		    left: 0;
		    border-left-color: $loader-color;
		  }
		  &:before {
		    right: 0;
		    border-right-color: $loader-color;
		    animation-delay: $loader-speed/-4;
		  }
		}

		@keyframes spin {

		  from {
		    transform: rotate(360deg);
		  }

		}

	}

}