@import "_fontfaces";

body{
	font-size:14px;
	color:#333333;
	font-family: 'latoitalic';
}

#app{
	overflow:hidden;
}

.logo{
	margin-bottom:0;
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.internas{

	img{
		@include img-fluid();
	}

}

.slick-slider{

	img{
		display:inline-block !important;
	}

	.item{
		outline:none !important;

		a{

			&:focus{
				box-shadow:none;
				outline:none !important;
			}

		}

	}

}

.text-theme{
	color:$theme !important;
}

// FONTS USADAS
.lato-blk{
	font-family: 'latoblack';
}

.lato-bld{
	font-family: 'lato-bold';
}

.lato-bld-itc{
	font-family: 'latobold_italic';
}

.lato-itc{
	font-family: 'latoitalic';
}

.lato-reg{
	font-family: 'latoregular';
}

.opns-lgt{
	font-family: 'open_sanslight';
}

.fnt-14{
	font-size:14px;
}

.fnt-18{
	font-size:18px;
}

.embed-1110x405{

	&:before{
		padding-top:(405 / 1110) * 100%;
	}

}

.embed-463x298{

	&:before{
		padding-top:(298 / 463) * 100%;
	}

}

.embed-728x430{

	&:before{
		padding-top:(430 / 728) * 100%;
	}

}

.max-width-100{
	max-width:100px;
	margin-left:auto;
	margin-right:auto;
}

.fnt-56{
	font-size:56px !important;
}

.text-black{
	color:#000 !important;
}

.w-lg-100{

	@include media-breakpoint-up(lg){
		width:100% !important;
	}

}

.carousel{

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

.slick-slider{

	.item{

		img{
			height:auto;
			max-width:100%;
		}

	}

}

:focus{
	outline:none !important;
}