.card-tab{
	padding-top:20px;
	background-color:#FFF;
	padding-left:10px;
	padding-right:10px;
	padding-bottom:10px;

	.col-lg-8{

		@include media-breakpoint-up(lg){
			text-align:left;
		}

		.desc{
			margin-bottom:13px;	
		}

	}

}