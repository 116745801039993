$icons: (
	// bg-position(x,y) width height
	'icon-aspas': 0px 0px 54px 48px,
	'icon-btn-form': 0px -58px 40px 41px,
	'icon-play': -64px 0px 42px 42px,
	'icon-prancheta': -116px 0px 28px 28px,
	'icon-relogio': -116px -38px 28px 26px,
	'icon-tel': -50px -58px 30px 30px,
	'icon-whats-small': 0px -109px 19px 18px,
	'icon-whats': -116px -74px 24px 24px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 144px 127px;

	@each $icon,$value in $icons {
		.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}