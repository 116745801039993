.paginacao-custom{
	font-size:18px;
	font-family: 'latoblack';
	flex-wrap:wrap;

	.page-item{
		@include margin(0,4);

		.page-link{
			width:42px;
			height:42px;
			line-height:42px;
			display:flex;
			flex-wrap:wrap;
			align-items:center;
			justify-content:center;
			text-align:center;
			padding:0;
			border-radius:100%;
			color:#FFF;
			background-color:$theme;
			border-color:$theme;
			@include sombra($theme2);
		}

		&.active > .page-link,
		&:hover  > .page-link{
			background-color:$theme2;
			color:#FFF;
			border-color:$theme2;
		}

	}

}