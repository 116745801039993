.topo{

	.nav-content{

		@include media-breakpoint-up(xl){
			@include make-container();
			@include make-container-max-widths();
		}

		.bar-principal{

			@include media-breakpoint-down(lg){
				margin-bottom:30px;
			}

			.logo{

				@include media-breakpoint-up(xl){
					flex-grow:1;
					max-width:196px;
				}

			}

			@include media-breakpoint-up(xl){
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				padding-top:7px;
				padding-bottom:7px;
			}

			.itens-bars{
				flex-grow:1;

				& > *{
					color:$theme;
					font-family: 'latobold_italic';
					line-height:1.2;
					padding-top:24px;
					padding-bottom:24px;
			
					@include media-breakpoint-up(xl){
						padding-right:20px;
						margin-bottom:0;

						&.link{
							padding-right:0;

							.media-incrivel{
								margin-bottom:0;

								.media-icon{
									margin-bottom:4px;
								}

							}

						}

					}
			
					& + *{
			
						@include media-breakpoint-down(lg){
			
							border-top:1px solid #E4E4E4;
			
						}
			
						@include media-breakpoint-up(xl){
							border-left:1px solid #E4E4E4;
							padding-left:25px;
						}
			
					}
			
					&.media-incrivel-diferente{
						padding:0;
						align-items:baseline;
			
						.media-icon{
							margin-bottom:4px;
						}
			
					}
			
				}

				@include media-breakpoint-up(xl){
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					max-width:920px;
				}

				& > *{
					flex-grow:1;
				}

			}

		}

		@include media-breakpoint-down(lg){
			position:fixed;
			left:-230px;
			width:230px;
			overflow:auto;
			height:100%;
			border-right:1px solid $theme;
			z-index:1000;
			background-color:#FFF;
			color:#000;
			transition:left 0.6s linear;

			.logo{
				padding:30px;
			}

			.btn-unidades-mobile{
				padding-left:15px;
				padding-right:15px;

				.btn{
					min-width:0;

					.fas{
						transition:transform 0.6s linear;
					}

					&[aria-expanded="true"]{

						.fas{
							transform:rotate(180deg);
						}

					}

				}

			}

			.itens-bars{
				text-align:center;

				& > *:last-child{
					display:none;
				}

				.media-incrivel-diferente{
					display:none;
				}

			}

		}

	}

	&.fx{

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			background-color:#FFF;
			box-shadow:0 0 8px rgba(#000,0.5);
			z-index:700;
			animation:fadeInDown 0.6s linear;
			max-width:100% !important;
		}

	}

}

body{

	.bg-menu{
		position:fixed;
		top:0;
		left:0;
		background-color:rgba(#000,0.5);
		width:100%;
		transition:opacity 0.6s linear;
		opacity:0;
	}

	&.sombra{

		.topo{

			.bg-menu{
				height:100%;
				opacity:1;
				z-index:980;
				height:100%;
			}

		}

	}

	&.menu-active{

		.topo{

			.nav-content{
				left:0;
			}

		}

	}

}