.card-especialidade{
	height:244px;
	border-radius:100%;
	background-color:$theme;
	color:#FFF;
	margin-bottom:25px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-wrap:wrap;
	border:5px outset $theme2;
	max-width:244px;
	margin-left:auto;
	margin-right:auto;
	overflow:hidden;

	@include media-breakpoint-up(lg){
		width:244px;
	}

	.conteudo-card{
		max-height:100%;
		padding:30px 40px;
		transition:all 0.6s linear;

		.title{
			font-family: 'latoblack';
			max-height:45px;
			max-width:180px;
			margin-left:auto;
			margin-right:auto;
			overflow:hidden;
			text-overflow:ellipsis;
			white-space:nowrap;

		}

		icon-awesome{
			display:block;
			position:relative;
			margin-left:auto;
			margin-right:auto;

			.icon-awesome{
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-color:#FFF;
			}

		}

		&.parte-frente{

			.img-card{
				margin-bottom:25px;

				icon-awesome{
					width:65px;
					height:65px;
				}

			}

			.title{
				font-size:16px;
				margin-bottom:25px;
			}

			.bar{
				display:block;
				border-bottom:2px solid #FFF;
				max-width:68px;
				margin-left:auto;
				margin-right:auto;
			}

		}

		&.parte-atras{
			opacity:0;
			transform:scale(0);

			.img-card{

				icon-awesome{
					width:30px;
					height:30px;

					.icon-awesome{
						background-color:$theme;
					}

				}

			}

			.img-card,
			.title{
				margin-bottom:2px;
			}

			.desc{
				max-height:120px;
				overflow:hidden;
			}

		}

	}

	&:hover{
		border-style:solid;
		border-color:$theme;
		background-color:#FFF;
		color:$theme;

		.conteudo-card{

			&.parte-frente{
				display:none !important;
			}

			&.parte-atras{
				display:block !important;
				opacity:1;
				transform:scale(1);
			}

		}

	}

}