.redes-sociais{

	& > *{
		@include margin(0,3);
		font-size:18px;
		width:(30 / 18) * 1em;
		height:(30 / 18) * 1em;
		line-height:(30 / 18) * 1em;
		text-align:center;
		background-color:$theme2;
		color:#FFF;
		@include sombra($theme2);
		border-radius:100%;

		@include hover-focus{
			background-color:darken($theme2,10%);
			color:#FFF;
		}

	}

	&.redes-sociais-topo{

	}

	&.redes-sociais-rodape{
		margin-bottom:6px;

		& > *{
			font-size:32px;
			width:(52 / 32) * 1em;
			height:(52 / 32) * 1em;
			line-height:(52 / 32) * 1em;
			color:$theme;
			background-color:#FFF;
			@include margin(0,13);
		}

	}

}