.comentario-cliente{
	margin-bottom:70px;
	position:relative;

	@include beforeAfter{
		content:'';
		display:block;
		@extend .icon;
		@extend .icon-aspas;
		width:54px;
		height:48px;

		@include media-breakpoint-down(md){
			margin-right:auto;
			margin-left:auto;
			margin-bottom:15px;
		}

		@include media-breakpoint-up(lg){
			position:absolute;
		}

	}

	&:before{
		top:0;
		left:0;
	}

	&:after{
		bottom:0;
		right:0;
		transform:rotate(180deg);
	}

	@include media-breakpoint-up(lg){
		padding-left:75px;
		padding-right:75px;
	}

	.desc{
		margin-bottom:62px;
	}

	.author{
		font-size:16px;
		color:$theme2;
		font-family: 'latobold_italic';
		position:relative;

		@include media-breakpoint-down(md){
			margin-bottom:15px;
		}

		@include beforeAfter{
			content:'-';
			display:inline-block;
		}

		&:before{
			margin-right:2px;
		}

		&:after{
			margin-left:2px;
		}

	}

}