.form-newsletter{
	max-width:446px;
	margin:0 auto;

	form{

		.input-group,
		.form-group {
			background-color:$theme;
			border-radius:30px;
			border:3px outset darken($theme2,20%);
			min-height:40px;

			.form-control{
				background:transparent;
				border:none;
				height:auto;
				border-radius:30px;
				@include place(#FFF);
				font-size:14px;

				&:focus{
					box-shadow:none;
				}

			}

			.btn{
				min-width:auto;
				padding:0;
				border:none;
			}

		}

	}

}