.form-tipo-1{

	form{

		.input-group,
		.form-group{
			margin-bottom:10px;

			.form-control{
				resize:none;
				border-radius:30px;
				padding-left:24px;
				@include place($theme);
				border-color:$theme;
				font-size:14px;
				min-height:43px;
				@include sombra($theme);
			}

			textarea.form-control{
				padding-top:10px;
				border-radius:20px;
			}

		}

	}

}

.form-max-730{
	max-width:730px;
	margin-left:auto;
	margin-right:auto;
}