.rodape{
	background-color:#014783;
	padding-top:70px;
	color:#FFF;
	padding-bottom:2px;
	text-align:center;
	@extend .angulacao-secao;
	position:relative;
	background-repeat:no-repeat;
	background-position:center center;

	.rodape-copyright{
		font-size:10px;
		font-family: 'open_sanslight';

		.gv8{
			font-weight:bold;
		}

	}

	.hr-divider{
		border-color:#00437A;
		margin-top:0;
		margin-bottom:10px;
	}

	.gv8{

		img{
			max-width:44px;
		}

	}

}