.article-equipe{
	text-align:left;
	font-family: 'latoregular';
	margin-bottom:15px;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.figure-article{
		margin-bottom:0;
		text-align:center;
	}

	.article-title,
	.link-label{
		font-family: 'lato-bold';
	}

	.article-title{
		margin-bottom:5px;
	}

	.subtitle-article{
		font-size:16px;
	}

	.link-label{
		font-size:18px;
	}

	&:hover{
		color:$theme;
	}

}