.nav-custom{
	border-bottom:none;
	overflow:hidden;

	@include media-breakpoint-down(md){
		display:block;
	}

	@include media-breakpoint-up(lg){
		display:flex;
		justify-content:space-between;
	}

	.nav-item{
		margin-bottom:0;
		flex-grow:1;
		width:100%;
		text-align:center;
		position:relative;


		@include media-breakpoint-up(lg){
			max-width:25%;
			display:flex;
		}

		&:nth-child(1){

			.nav-link{
				z-index:5;
			}

		}

		&:nth-child(2){

			.nav-link{
				z-index:4;
			}

		}

		&:nth-child(3){

			.nav-link{
				z-index:3;
			}

		}

		&:nth-child(4){

			.nav-link{
				z-index:2;
			}

		}

		@include media-breakpoint-down(md){
			margin-bottom:10px;
		}

		.nav-link{
			padding:3px 15px;
			font-size:16px;
			border:none;
			font-family: 'lato-bold';
			background-color:$theme2;
			color:#FFF;
			border-radius:15px 15px 0 0;
			border:2px outset $theme2;

			@include media-breakpoint-up(lg){
				display:flex;
				width:100%;
				align-items:center;
				justify-content:center;
			}

			@include media-breakpoint-down(md){
				border-radius:30px;
				min-width:auto;
			}

			&:not(.active){

				&:hover{
					background-color:darken($theme2,10%);
				}

			}

			&.active{
				background-color:$theme;
			}

		}

		& + .nav-item{

			.nav-link{
				
				@include media-breakpoint-up(lg){
					
				}

			}

		}

	}

}

.nav-custom,
.tab-content{
	max-width:550px;
	margin-left:auto;
	margin-right:auto;

	@include media-breakpoint-up(lg){
		margin-right:0;
	}

}