.card-unidades{
	max-width:354px;
	margin-bottom:30px;
	margin-left:auto;
	margin-right:auto;

	.figure-card{
		margin-bottom:30px;
		border:3px solid $theme2;
		transition:border-color 0.6s linear;

		@include media-breakpoint-up(lg){
			border-color:transparent;
		}

	}

	.content-card{

		.title{
			color:$theme2;
			font-size:18px;
			font-family: 'latoblack';
			transition:color 0.6s linear;

			@include media-breakpoint-up(lg){
				color:$theme;
			}

		}

	}

	&:hover{

		.figure-card{
			border-color:$theme2;
		}

		.content-card{

			.title{
				color:$theme2;
			}

		}

	}

}