.menu-topo{
	list-style-type:none;
	padding-left:0;
	margin-bottom:0;
	font-family: 'lato-bold';
	background-color:$theme;
	color:#FFF;

	@include media-breakpoint-up(xl){
		position:relative;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		align-items:center;

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			top:0;
			background-color:inherit;
			width:100vw;
			height:100%;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

	}

	& > li{

		&.active,
		&:hover{

			&:before{
				width:100%;
			}
			
		}

		@include media-breakpoint-up(xl){
			position:relative;

			&:before{
				content:'';
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				width:0;
				transition:width 0.6s linear;
				height:2px;
				background-color:$theme2;
			}

		}

		&.active > a,
		&:hover  > a{

			@include media-breakpoint-up(xl){
				color:$theme2;
			}

			@include media-breakpoint-down(lg){
				background-color:$theme2;
				color:#FFF;
				border-color:darken($theme2,20%);
			}

		}

		& > a{
			display:block;

			@include media-breakpoint-up(xl){
				padding-top:10px;
				padding-left:3px;
				padding-right:3px;
				padding-bottom:10px;
			}

			@include media-breakpoint-down(lg){
				padding:10px 15px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

		}

	}

}