.box-float{
	width:100%;
	max-width:924px;
	margin-left:auto;
	margin-right:auto;
	background-color:$theme2;
	color:#FFF;
	padding-top:24px;
	padding-bottom:20px;
	border-bottom:5px solid $theme;
	font-family: 'lato-bold';
	margin-bottom:28px;
	position:relative;
	z-index:200;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;

	@include media-breakpoint-up(lg){
		padding-left:82px;
		margin-top:-18px;
		padding-right:82px;
	}

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.media-incrivel{
		font-family: 'latobold_italic';

		@include media-breakpoint-down(md){
			margin-bottom:25px;
		}

		.media-icon{

			@include media-breakpoint-up(lg){
				margin-right:8px;
			}

		}

		.media-text{
			line-height:1;
		}

	}

	.row-data{
		align-items:center;

		@include media-breakpoint-down(md){
			margin-bottom:25px;
		}

		& > *{
			margin-bottom:4px;
		}

		.bar{

			@include media-breakpoint-up(lg){
				display:inline-block;
				vertical-align:middle;
				width:100%;
				height:2px;
				background-color:#FFF;
				position:relative;

				@include beforeAfter{
					content:'';
					display:block;
					position:absolute;
					top:0;
					width:20px;
					height:100%;
					background-color:inherit;
				}

				&:before{
					left:100%;
				}

				&:after{
					right:100%;
				}

			}

		}

	}

}