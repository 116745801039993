.slick-dots{
	padding-left:0;
	list-style-type:none;
	margin-bottom:0;

	& > li{
		line-height:1;
		@include margin(0,4);
		display:inline-block;

		button{
			width:6px;
			height:6px;
			display:block;
			background-color:$theme;
			border:none;
			padding:0;
			border-radius:100%;
			text-indent:-300vw;
			position:relative;

			&:focus{
				box-shadow:none !important;
				outline:none !important;
			}


			&:before{
				content:'';
				transition:border-color 0.6s linear;
				display:block;
				width:calc(100% + 4px);
				height:calc(100% + 4px);
				border-radius:100%;
				background-color:transparent;
				border:1px solid transparent;
				position:absolute;
				top:-2px;
				left:-2px;
			}

		}

		&.slick-active{

			button{

				&:before{
					border-color:$theme;
				}

			}

		}

	}

}