.banner{

	.carousel-item{
		text-align:center;
		margin-bottom:0;
	}

	.carousel-controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		align-items:flex-end;
		justify-content:flex-end;
		padding-bottom:20px;

		@include media-breakpoint-up(lg){
			padding-bottom:82px;
		}

		.clearfix{
			width:100%;

			@include media-breakpoint-up(lg){
				@include make-container();
				@include make-container-max-widths();
			}


			& > *{
				z-index:200;
				position:relative;
			}

		}

	}

}

.duas-sections{
	background-repeat:no-repeat;
	background-size:cover;
	background-attachment:fixed;
	background-position:center center;
}

.bem-vindo{
	position:relative;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.circle-absolute{
		line-height:1;
		width:224px;
		height:224px;
		color:#FFF;
		background-color:$theme;
		display:flex;
		align-items:center;
		justify-content:center;
		flex-wrap:wrap;
		text-align:center;
		border-radius:100%;
		font-size:30px;
		min-height:0 !important;
		border:8px solid #FFF;
		margin-right:auto;
		margin-left:auto;
		margin-bottom:30px;

		@include media-breakpoint-up(lg){
			position:absolute;
			margin-bottom:0;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
		}

		.bold{
			font-family: 'latoblack';
		}

	}

	.d-lg-flex{

		& > *{
			min-height:466px;
			background-repeat:no-repeat;
			background-size:cover;
		}

		.parte-um{

			@include media-breakpoint-up(lg){
				width:(962 / 1920) * 100%;
				background-position:right;
			}

			@include media-breakpoint-down(md){
				background-position:center center;
			}

		}

		.parte-dois{

			@include media-breakpoint-down(md){
				padding-left:15px;
				padding-right:15px;
			}

			@include media-breakpoint-between(sm,md){
				@include make-container();
				@include make-container-max-widths();
			}

			@include media-breakpoint-up(lg){
				width:(958 / 1920) * 100%;
				max-width:552px;
				padding-left:122px;
				padding-top:2px;
				margin-right:auto;
				display:flex;
				justify-content:flex-end;
				align-items:flex-start;
				flex-direction:column;
				text-align:left;
				min-height:150px;

				.max-height{
					overflow:auto;

					&.max-height-50{
						max-height:40px;
					}

					&.max-height-230{
						max-height:230px;
					}

				}

			}

		}

	}

}

.carousel-controls{

	.clearfix{
		color:#FFF;
		font-size:25px;
		padding-left:15px;
		padding-right:15px;
	}

}

.quem-somos{
	padding-bottom:32px;
}

.especialidades{
	background-color:#EDEDED;
	padding-top:28px;
	padding-bottom:26px;
	text-align:center;
}

.section-saude{
	padding-top:50px;
	padding-bottom:4px;
	text-align:center;
	position:relative;

	@include media-breakpoint-up(lg){
		text-align:right;
	}

	.row-principal{

		&:before{
			content:'';
			display:block;
			width:100%;
			position:absolute;
			top:0;
			left:calc(50% - 56px);
			height:100%;
			background-image:url('../images/bg-saude.png');
			background-repeat:no-repeat;
			background-position:left;
			background-size:cover;
			background-attachment:fixed;
		}

	}

}

.section-depoimentos{
	padding-top:24px;
	background-color:$theme;
	color:#FFF;
	text-align:center;
	padding-bottom:16px;
	background-size:cover;
	background-attachment:fixed;
}

.section-unidades{
	padding-top:25px;
	padding-bottom:93px;
	text-align:center;
	background-size:cover;
	background-attachment:fixed;
	background-position:center;
}

.section-depoimentos,
.section-unidades{
	background-repeat:no-repeat;
	background-position:center center;

}

.angulacao-secao{
	position:relative;

	&:before{
		content:'';
		display:block;
		position:absolute;
		top:0;
		left:50%;
		transform:translateX(-50%);
		background-repeat:no-repeat;
		width:139px;
		height:35px;
		background-image:url('../images/efeito-angulacao-secao.png');
	}

}