.header-section{

	& > *{
		line-height:1;
		margin-bottom:0;
	}

	.subtitle-section{
		font-size:18px;
		font-family: 'latoregular';
		color:$theme2;
	}

	.title-section{
		color:$theme;
		font-family: 'latobold_italic';
		font-size:30px;
	}

}