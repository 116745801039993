@import "_home.scss";

.internas{
	padding-bottom:90px;

	.section-breadcrumb-internas{
		padding-top:35px;
		padding-bottom:35px;
		text-align:right;
		font-family: 'latoregular';
		font-size:18px;

		.breadcrumb{
			background-color:transparent;
			padding:0;
			margin:0;
			justify-content:flex-end;
			color:$theme;

			.breadcrumb-item{

				span{
					font-weight:bold;

					& + span{

						&:before{
							content:'|';
							display:inline-block;
							margin:0 6px 0 2px;
						}

					}

				}

			}

		}

	}

	.internas-content{
		padding-top:42px;
		text-align:center;
	}

	.efeito-youtube{
		position:relative;

		.controls-image{
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background-color:rgba(#000,0.5);
			transition:opacity 0.6s linear;
			display:flex;
			flex-wrap:wrap;
			justify-content:center;
			align-items:center;

			@include media-breakpoint-up(lg){
				opacity:0;
			}

		}

		&:hover{

			.controls-image{
				opacity:1;
			}

		}

	}

	.galeria-col{

		.row-galeria{

			img{
				border:1px solid $theme;
				transition:border-color 0.6s linear;

				@include media-breakpoint-up(lg){
					border-color:transparent;

					&:hover{
						border-color:$theme;
					}

				}

			}

		}

	}

	&.internas-depoimentos{

		.internas-content{

			.section-before-gray .container,
			.depoimento-interna .container{
				max-width:740px;
				margin-left:auto;
				margin-right:auto;
			}

			.section-before-gray{
				position:relative;
				padding-bottom:50px;

				&:before{
					content:'';
					display:block;
					position:absolute;
					bottom:0;
					left:0;
					width:100%;
					min-height:380px;
					background-color:#EDEDED;
				}
				
				.container{
					
				}

			}

			.carousel-item{
				// z-index:200;
				// position:relative;
			}

			.carousel-controls{

				&.carousel-controls-absolute{

					@include media-breakpoint-up(lg){
						position:absolute;
						top:0;
						left:0;
						width:100%;
						height:100%;
						display:flex;
						flex-wrap:wrap;
						justify-content:center;
						align-items:center;

						.clearfix{
							width:100%;
							max-width:100% !important;
							padding:0;

							[data-slide="prev"]{
								left:-55px;
								position:relative;
							}

							[data-slide="next"]{
								right:-55px;
								position:relative;
							}

						}

					}

				}

			}

			.depoimento-interna{
				padding-top:26px;
			}

		}

	}

}