.article-saude{

	.row{

		& > *{
			margin-bottom:28px;
		}

	}

	.content-card{
		padding-top:18px;

		@include media-breakpoint-up(lg){
			text-align:left;
		}

		.title{
			font-size:18px;
			color:$theme;
			font-family: 'latoblack';
			margin-bottom:20px;
		}

		.desc{
			font-family: 'latoregular';
			margin-bottom:20px;
		}

	}

}